<template>
  <v-card>
    <v-card-title>Ghost the System</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="6">
          <v-img contain :src="require('@/assets/images/ghost-the-system-panels/ghost-the-system-panel-1.png')"></v-img>
        </v-col>
        <v-col cols="12" md="6">
          <v-img contain :src="require('@/assets/images/ghost-the-system-panels/ghost-the-system-panel-2.png')"></v-img>
        </v-col>
        <v-col cols="12" md="6">
          <v-img contain :src="require('@/assets/images/ghost-the-system-panels/ghost-the-system-panel-3.png')"></v-img>
        </v-col>
        <v-col cols="12" md="6">
          <a href="https://qr.ae/pvFXAy" target="_blank">
            <v-img contain :src="require('@/assets/images/ghost-the-system-panels/ghost-the-system-panel-4.png')"></v-img>
          </a>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>

export default {
  name: 'GhostComic',

  components: {
  }
}
</script>
